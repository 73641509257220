import React, {useState, useEffect, useRef} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {HashLink as Link} from 'react-router-hash-link';
import {useWindowSize} from "@uidotdev/usehooks";
import HeaderBlock from './Components/HeaderBlock';
import SolutionBlock from './Components/SolutionBlock';
import DetailsBlock from './Components/DetailsBlock';
import YouTubeBlock from "./Components/YouTubeBlock";
import PanelBlock from "./Components/PanelBlock";
import ProgressBlock from './Components/ProgressBlock';
import SubscriptionBlock from './Components/SubcriptionBlock';
import ContactModal from "./Components/ContactModal";
import BillModal from "./Components/BillModal";
import DemonstrationModal from "./Components/DemonstrationModal";
import './styles/App.css';
import './styles/Menu.css';
import logo from './images/App/logo.png';
import PhoneIcon from './Components/Icons/PhoneIcon';
import gPlay from './images/App/gPlay.png';
import appStore from './images/App/appStore.png';
import gPlayTablet from './images/App/gPlayTablet.png';
import appStoreTablet from './images/App/appStoreTablet.png';
import gPlayFooter from './images/App/gPlayFooter.svg';
import appStoreFooter from './images/App/appStoreFooter.svg';
import logoBlack from './images/App/logoBlack.svg';
import phoneIconTab from './images/App/phoneIconTab.svg';
import phoneIconMobile from './images/App/phoneIconMobile.svg';
import ContactBlock from "./Components/ContactBlock";
import downloadIcon from "./images/App/downloadIcon.svg";
import Presentation from '../src/files/MonSTR_Presentation.pdf';
import Doc1 from '../src/files/Функциональность программного продукта Монстр.pdf';
import Doc2 from '../src/files/Инструкция по установке ПО.pdf';
import Doc3 from '../src/files/Инструкция для эксплуатации экземпляра ПО.pdf';
import Doc4 from "../src/files/Пользовательское соглашение об условиях использования МонСтр.pdf";

function Browser({size}) {
	return null;
}

export default function App() {

	const [contactModal, setContactModal] = useState(false);
	const [showBurgerMenu, setShowBurgerMenu] = useState(false);
	const [billModal, setBillModal] = useState(false);
	const [demonstrationModal, setDemonstrationModal] = useState(false);
	const [openRightMenu, setOpenRightMenu] = useState(false);
	const [name, setName] = useState('');
	const [emptyName, setEmptyName] = useState(false);
	const [email, setEmail] = useState('');
	const [emptyEmail, setEmptyEmail] = useState(false);
	const [service, setService] = useState('');
	const [comment, setComment] = useState('');
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState(false);
	const [showServices, setShowServices] = useState(false);
	const size = useWindowSize();

	const interfaceRef = useRef(null);
	const systemRef = useRef(null);
	const servicesRef = useRef(null);
	const contactRef = useRef(null);

	const [mobileView, setMobileView] = useState(true);
	useEffect(() => {
		document.body.style.overflow = contactModal ? 'hidden' : 'auto';
	}, [contactModal])

	useEffect(() => {
		document.body.style.overflow = billModal ? 'hidden' : 'auto';
	}, [billModal])

	useEffect(() => {
		document.body.style.overflow = demonstrationModal ? 'hidden' : 'auto';
	}, [demonstrationModal])

	const sendEmail = async () => {
		const parametrs = {
			name,
			email,
			// phone,
			service,
			comment,
		}
		setLoading(true);
		const {status} = await fetch('/api/email/send', {
			method: 'POST',
			body: JSON.stringify(parametrs),
		});
		setLoading(false);
		if (status !== false) {
			// openNotificationWithIcon('success', "Заявка успешно отправлена");
			setContactModal(false);
			setName('');
			setEmail('');
			setService('');
			setComment('');
			setChecked(false);
		} else {
			// openNotificationWithIcon('error', "Заявка не отправлена. Повторите попытку");
		}
	}

	const validateEmail = (email) => {
		setEmail(email);
		/*        if (!validator.isEmail(email)) {
								setErrorMessage(!mobileView ? 'Поля обязательны для заполнения' : 'Обязательно для заполнения');
								setEmptyEmail(true);
						} else {
								setEmptyEmail(false);
						}*/
	}

	const validateName = (name) => {
		setName(name);
		if (name.trim().length) {
			setEmptyName(false);
			if (email.length) {
				// setErrorMessage('');
			}
		} else {
			// setErrorMessage(!mobileView ? 'Поля обязательны для заполнения' : 'Обязательно для заполнения');
			setEmptyName(true);
		}
	}

	useEffect(() => {
		setMobileView(size.width < 1024 ? true : false);
	}, [size]);

	useEffect(() => {
		document.body.style.overflow = contactModal ? 'hidden' : 'auto';
	}, [contactModal])

	const openContactModal = (value, modal) => {
		setContactModal(value);
		// setName('');
		// setEmail('');
		// setService('');
		// setComment('');
		// setChecked(false);
	}

	const scrollUp = (elem) => {
		const y = elem.current.getBoundingClientRect().top + window.scrollY - 120;
		window.scrollTo({top: y, behavior: 'smooth'});
	}

	const openDemonstrationModal = (value, modal) => {
		setDemonstrationModal(value);
	}

	const openBillModal = (value, modal) => {
		setBillModal(value);
	}

	const checkForm = () => {
		if (!name.trim().length) {
			setEmptyName(true);
		}
		// if (!validator.isEmail(email)) {
		//     setEmptyEmail(true);
		// }
	}

	return (
		<BrowserRouter>
			<div
				className="app"
				onClick={() => {
					if (contactModal) {
						setContactModal(false);
						setName('');
						setEmail('');
						setService('');
						setComment('');
						setChecked(false);
					}
					if (openRightMenu) {
						setOpenRightMenu(false);
					}
				}}
				style={{overflow: 'hidden'}}>
				<header className="app-header">
					<div className="app-header-wrapper">
						{size.width < 1201 ?
							<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
								<div className="menu">
									<input type="checkbox" id="burger-checkbox" checked={showBurgerMenu}
												 onClick={() => {
													 setShowBurgerMenu(!showBurgerMenu)
												 }} className="burger-checkbox"/>
									<label htmlFor="burger-checkbox" className="burger"/>
									<ul className="menu-list">
										<li className="menu-item" onClick={() => {
											setShowBurgerMenu(false);
										}}>
										</li>
										<li className="menu-item" onClick={() => {
											setShowBurgerMenu(false);
											interfaceRef.current.scrollIntoView({behavior: "smooth"});
										}}> Интерфейс
										</li>
										<li className="menu-item" onClick={() => {
											setShowBurgerMenu(false);
											systemRef.current.scrollIntoView({behavior: "smooth"});
										}}> О системе
										</li>
										<li className="menu-item" onClick={() => {
											setShowBurgerMenu(false);
											servicesRef.current.scrollIntoView({behavior: "smooth"})
										}}> Решения
										</li>
										<li className="menu-item" onClick={() => {
											setShowBurgerMenu(false);
											contactRef.current.scrollIntoView({behavior: "smooth"})
										}}> Контакты
										</li>
										<li>
											<a
												href={Presentation}
												download="МонСТР Презентация"
												target="_blank"
												rel="noopener noreferrer"
												className="transparent-btn"
											>
												<img src={downloadIcon} alt={""}/>
												<p>Скачать презентацию</p>
											</a>
											<div
												style={{display: 'flex', maxWidth: '320px', marginLeft: '3%', width: '90%', justifyContent: 'space-between'}}>
												<div className="icon-container">
													<a
														href="https://play.google.com/store/apps/details?id=ru.spb.cds.control&hl=ru&gl=US">
														<img src={gPlay} className="mobileIcon" alt=""/>
													</a>
												</div>
												<div className="icon-container">
													<a
														href="https://apps.apple.com/ru/app/%D1%86%D0%B4%D1%81-%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%BE%D0%BB%D1%8C/id1493947271">
														<img src={appStore} className="mobileIcon" alt=""/>
													</a>
												</div>
											</div>
										</li>
									</ul>
									<div className="app-header-container menu-list">
										<a className="app-header-container-phone" href="tel:+78123397441">
											<span style={{textDecoration: 'underline'}}>Связаться с нами</span>
											<img src={phoneIconMobile} alt={""} style={{marginLeft: '6px'}}/>
										</a>
									</div>
								</div>
								<div className="app-header-logo-wrapper">
									<img
										src={logo}
										alt=''
										onClick={() => {
											window.scrollTo({
												top: 0,
												left: 0,
											});
										}}
									/>
								</div>
							</div> :
							<div className="app-header-logo-wrapper">
								<img
									src={logo}
									alt=''
									onClick={() => {
										window.scrollTo({
											top: 0,
											left: 0,
										});
									}}
								/>
							</div>
						}
						{size.width < 1201 ? null :
							<div className="app-header-container">
								<div className="app-header-container-menu">
									<div onClick={() => scrollUp(interfaceRef)}> Интерфейс</div>
									<span className="app-header-container-menu-divider"/>
									<div onClick={() => scrollUp(systemRef)}> О системе
									</div>
									<span className="app-header-container-menu-divider"/>
									<div onClick={() => scrollUp(servicesRef)}> Решения</div>
									<span className="app-header-container-menu-divider"/>
									<div onClick={() => scrollUp(contactRef)}>Контакты</div>
								</div>
								<div className="app-header-container-contacts">
									<div onMouseOver={() => setShowServices(false)}>
										{size.width > 1450 ? <a className="app-header-container-phone" href="tel:+78123397441">
											<PhoneIcon/>
											<span style={{height: '24px'}}>+7 (812) 339-74-41</span>
										</a> : <a className="app-header-tab-phone" href="tel:+78123397441">
											<span style={{textDecoration: 'underline'}}>Связаться с нами</span>
											<img src={phoneIconTab} alt={""}/>
										</a>
										}
									</div>
									<a
										href={Presentation}
										download="МонСТР Презентация"
										target="_blank"
										rel="noopener noreferrer"
										className="transparent-btn"
									>
										<img src={downloadIcon} alt={""}/>
										<p>Скачать презентацию</p>
									</a>
									<div className="icon-container">
										<a
											href="https://play.google.com/store/apps/details?id=ru.spb.cds.control&hl=ru&gl=US">
											<img src={size.width < 1450 ? gPlayTablet : gPlay} className="mobileIcon" alt=""/>
										</a>
									</div>
									<div className="icon-container">
										<a
											href="https://apps.apple.com/ru/app/%D1%86%D0%B4%D1%81-%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%BE%D0%BB%D1%8C/id1493947271">
											<img src={size.width < 1450 ? appStoreTablet : appStore} className="mobileIcon" alt=""/>
										</a>
									</div>
									{mobileView && <div style={{marginTop: '8px'}}>
										<input type='checkbox' checked={openRightMenu} onChange={(e) => {
											setOpenRightMenu(!openRightMenu)
										}} style={{display: 'none'}} id='rightMenu'/>
										<label htmlFor="rightMenu"><img alt="" style={{height: '22px'}}/></label>
									</div>}
								</div>
							</div>
						}
					</div>
				</header>
				<main className="app-main">
					{contactModal && <ContactModal openModal={openContactModal} size={size}/>}
					{demonstrationModal && <DemonstrationModal openModal={openDemonstrationModal} size={size}/>}
					{billModal && <BillModal openModal={openBillModal} size={size}/>}
					<HeaderBlock openDemonstrationModal={openDemonstrationModal} openBillModal={openBillModal} size={size}/>
					<SolutionBlock refProp={servicesRef} openDemonstrationModal={openDemonstrationModal}
												 openBillModal={openBillModal} size={size}/>
					<DetailsBlock/>
					<YouTubeBlock refProp={systemRef} size={size}/>
					<PanelBlock refProp={interfaceRef} size={size}/>
					<ProgressBlock openModal={openContactModal} size={size}/>
					<ContactBlock refProp={contactRef} size={size}/>
					{/*<SubscriptionBlock size={size}/>*/}
					<section className="app-main-footer">
						<div className="app-main-footer-logo">
							<img src={logoBlack} alt=""/>
							{size.width > 1024 ?
								<div className="footer">
									<div>
										<span>Монстр</span>
										<p onClick={() => scrollUp(interfaceRef)}>Интерфейс</p>
										<p onClick={() => scrollUp(servicesRef)}>Решения</p>
										<p onClick={() => scrollUp(systemRef)}>Как это работает</p>
										<p onClick={() => scrollUp(contactRef)}>Контакты</p>
									</div>
									<div>
										<span>О продукте</span>
										<a
											href={Doc1}
											download="Функциональность программного обеспечения"
											target="_blank"
											rel="noopener noreferrer"
										>Функциональность программного обеспечения</a>
										<a
											href={Doc2}
											download="Инструкция по установке ПО"
											target="_blank"
											rel="noopener noreferrer"
										>Инструкция по установке ПО</a>
										<a
											href={Doc3}
											download="Инструкция для эксплуатации экземпляра ПО"
											target="_blank"
											rel="noopener noreferrer"
										>Инструкция для эксплуатации экземпляра ПО</a>
										<a
											href={Doc4}
											download="Пользовательское соглашение об условиях использования"
											target="_blank"
											rel="noopener noreferrer"
										>Пользовательское соглашение об условиях использования</a>
									</div>
								</div> :
								<div className="footer">
									<div>
										<input type="checkbox" id="footer-one"/>
										<label htmlFor="footer-one">Монстр </label>
										<div className="hidden">
											<p onClick={() => scrollUp(interfaceRef)}>Интерфейс</p>
											<p onClick={() => scrollUp(servicesRef)}>Решения</p>
											<p onClick={() => scrollUp(systemRef)}>Как это работает</p>
											<p onClick={() => scrollUp(contactRef)}>Контакты</p>
										</div>
									</div>
									<div>
										<input type="checkbox" id="one"/>
										<label htmlFor="one"> О продукте </label>
										<div className="hidden">
											<a
												href={Doc1}
												download="Функциональность программного обеспечения"
												target="_blank"
												rel="noopener noreferrer"
											>Функциональность программного обеспечения</a>
											<a
												href={Doc2}
												download="Инструкция по установке ПО"
												target="_blank"
												rel="noopener noreferrer"
											>Инструкция по установке ПО</a>
											<a
												href={Doc3}
												download="Документ необходимый для эксплуатации экземпляра программного обеспечения МонСтр"
												target="_blank"
												rel="noopener noreferrer"
											>Инструкция для эксплуатации экземпляра ПО</a>
											<a
												href={Doc4}
												download="Политика обработки персональных данных"
												target="_blank"
												rel="noopener noreferrer"
											>Политика обработки персональных данных</a>
										</div>
									</div>
								</div>}
						</div>
						{size.width > 1024 ? <div style={{height: '1px', backgroundColor: '#D4D4D4'}}/> : null}
						<div className="footer-contact">
							<span> Все права защищены ООО "МонСТР" {new Date().getFullYear()}</span>
							{size.width > 1024 ? <div className="phone-wrapper">
								<a className="app-header-container-phone" href="tel:+78123397441">
									<PhoneIcon/>
									<span>+7 (812) 339-74-41</span>
								</a>
							</div> : null
							}
							<div className="icon-wrapper">
								<a
									href="https://apps.apple.com/ru/app/%D1%86%D0%B4%D1%81-%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%BE%D0%BB%D1%8C/id1493947271">
									<img src={appStoreFooter} className="mobileIcon" alt=""/>
									<span>App Store</span>
								</a>
								<a
									href="https://play.google.com/store/apps/details?id=ru.spb.cds.control&hl=ru&gl=US">
									<img src={gPlayFooter} className="mobileIcon" alt=""/>
									<span>Google Play</span>
								</a>
							</div>
						</div>
					</section>
				</main>
			</div>
			<Browser size={size}/>
		</BrowserRouter>
	);
}